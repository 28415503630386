import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './assets/robotofonts.css';
import './App.scss';
import PrivateRoute from './components/PrivateRoute';
import Home from './containers/Home';
import Photos from './containers/Photos';
import EditAthlete from './containers/EditAthlete';
import Login from './containers/Login';
import SignUp from './containers/SignUp';
import RegistrationPage from './containers/Registration';
import WompiResponsePage from './containers/WompiResponse';

const App = () => {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <PrivateRoute exact path='/ranking' component={Home} />
          <PrivateRoute exact path='/photos' component={Photos} />
          <PrivateRoute exact path='/edit' component={EditAthlete} />
          <Route path='/registration' component={RegistrationPage} />
          <Route
            path='/payment-success/:userEventRegistrationId'
            component={WompiResponsePage}
          />
          <Route path='/payment-success' component={WompiResponsePage} />
          <Route path='/login' component={Login} />
          <Route path='/welcome' component={Login} />
          <Route path='/sign-up' component={SignUp} />
          <Redirect from='*' to='/ranking' />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
