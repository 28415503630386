import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';
import './registration.scss';
import config from '../../config';
import {
  handleStravaResponse,
  serializeQuery,
  handleResponse,
} from '../../utils/handleResponse';
import Footer from '../../components/Footer';
import Header from '../Header';
import Loading from '../../components/loading';
import { SHOES, BIKES, GENDERS, SIZES } from '../../utils/utils';

const RegistrationPage = () => {
  const [registrationTypes, setRegistrationTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userExist, setUserExist] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [debouncedEmail, setDebouncedEmail] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    birthdate: '',
    identificationCard: '',
    sizeShirt: '',
    segmentName: '',
    bicycleBrand: '',
    shoesBrand: '',
    address: '',
    countryId: '',
    stateId: '',
    cityId: '',
    stravaId: '',
    refreshToken: '',
    segmentId: '',
    segmentStravaId: '',
    segmentCity: '',
    cityName: '',
    countryName: '',
    prTime: null,
    prDate: null,
    photoUrl: null,
    sportType: '',
  });

  const [errors, setErrors] = useState({});
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [runSegments, setRunSegments] = useState([]);
  const [bikeSegments, setBikeSegments] = useState([]);
  const [segments, setSegments] = useState([]);
  const [accessToken, setAccessToken] = useState(null);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = 'El nombre es obligatorio.';
    if (!formData.lastName) newErrors.lastName = 'El apellido es obligatorio.';
    if (!formData.email) newErrors.email = 'El email es obligatorio.';
    if (!isValidEmail(formData.email))
      newErrors.email = 'El email no es válido.';
    if (!formData.phone) newErrors.phone = 'El teléfono es obligatorio.';
    if (!formData.gender) newErrors.gender = 'El género es obligatorio.';
    if (!formData.birthdate)
      newErrors.birthdate = 'La fecha de nacimiento es obligatoria.';
    if (!formData.identificationCard)
      newErrors.identificationCard = 'La cédula es obligatoria.';
    if (!formData.sizeShirt)
      newErrors.sizeShirt = 'La talla del jersey es obligatoria.';
    if (!formData.segmentId)
      newErrors.segmentId = 'El Segmento es obligatorio.';
    if (!formData.address) newErrors.address = 'La dirección es obligatoria.';
    if (!formData.countryId) newErrors.countryId = 'El país es obligatorio.';
    if (!formData.stateId) newErrors.stateId = 'El estado es obligatorio.';
    if (!formData.cityId) newErrors.cityId = 'La ciudad es obligatoria.';
    if (selectedType?.type === 'bike' && !formData.bicycleBrand) {
      newErrors.bicycleBrand = 'La marca de bicicleta es obligatoria.';
    }
    if (selectedType?.type === 'run' && !formData.shoesBrand) {
      newErrors.shoesBrand = 'La marca de zapatos es obligatoria.';
    }
    console.log('newErrors', newErrors);

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const getAccesToken = async (refreshToken) => {
    setShowLoading(true);
    const url = new URL(`https://www.strava.com/oauth/token`);
    const params = {
      client_id: config.clientID,
      client_secret: config.clientSecret,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    };
    const requestOptions = {
      method: 'POST',
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      const refreshToken = body.refresh_token;
      setAccessToken(body.access_token);

      setFormData({
        ...formData,
        refreshToken,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaSegmentEffort = async (effortId) => {
    setShowLoading(true);
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts/${effortId.toString()}`
    );
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);

    setShowLoading(false);
    try {
      const stravaAthleteEffort = await handleResponse(response);
      setFormData({
        ...formData,
        prTime: stravaAthleteEffort.athlete_segment_stats.pr_elapsed_time,
        prDate: stravaAthleteEffort.athlete_segment_stats.pr_date,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaSegmentEfforts = async (segmentId) => {
    setShowLoading(true);
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts?segment_id=${segmentId}`
    );
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthleteEfforts = await handleStravaResponse(response);
      if (stravaAthleteEfforts && stravaAthleteEfforts.length > 0) {
        getStravaSegmentEffort(stravaAthleteEfforts[0].id);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchSegments = async (sportType) => {
    try {
      const response = await fetch(
        `${config.tncApiUrl}/segments?sportEventId=${config.eventId}&sportType=${sportType}`
      );
      const data = await response.json();
      if (data && data.data && data.data.segments) {
        return data.data.segments;
      }
      return [];
    } catch (error) {
      console.error(`Error fetching ${sportType} segments:`, error);
      return [];
    }
  };

  const fetchCountries = async () => {
    setShowLoading(true);
    try {
      const response = await fetch(`${config.tncApiUrl}/countries`);
      const data = await response.json();
      setCountries(data.data.body);
    } catch (error) {
      console.error('Error fetching countries:', error);
    } finally {
      setShowLoading(false);
    }
  };

  const fetchStates = async (countryId) => {
    setShowLoading(true);
    try {
      const response = await fetch(
        `${config.tncApiUrl}/countries/${countryId}/states`
      );
      const data = await response.json();
      setStates(data.data.body);
    } catch (error) {
      console.error('Error fetching states:', error);
    } finally {
      setShowLoading(false);
    }
  };

  const fetchCities = async (stateId) => {
    setShowLoading(true);
    try {
      const response = await fetch(
        `${config.tncApiUrl}/states/${stateId}/cities`
      );
      const data = await response.json();
      setCities(data.data.body);
    } catch (error) {
      console.error('Error fetching cities:', error);
    } finally {
      setShowLoading(false);
    }
  };

  const fetchRegistrationTypes = async () => {
    setShowLoading(true);
    try {
      const response = await fetch(
        `${config.tncApiUrl}/sport-events/${config.eventId}`
      );
      const data = await response.json();
      setRegistrationTypes(data.data.registrationTypes);
    } catch (error) {
      console.error('Error fetching registration types:', error);
    } finally {
      setShowLoading(false);
    }
  };

  const loginStrava = async (userData, stravaId) => {
    setShowLoading(true);
    try {
      const payload = {
        stravaId,
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${config.tncApiUrl}/user/login`,
        requestOptions
      );

      if (!response.ok) {
        setShowLoading(false);
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en el registro del evento');
      }

      const result = await response.json();
      setShowLoading(false);
      localStorage.setItem(
        'userData',
        JSON.stringify({ ...userData, userToken: result.data.accessToken })
      );
      window.location.replace('/');
    } catch (error) {
      setShowLoading(false);
      console.error('Error en la solicitud de registro:', error);
      alert(error.message || 'Error al generar el pago');
    }
  };

  const userRegistrationStatusEmail = async (debouncedEmail) => {
    setShowLoading(true);

    try {
      const url = new URL(
        `${config.tncApiUrl}/user-registration-status-by-email`
      );
      url.searchParams.append('sportEventId', config.eventId);
      url.searchParams.append('email', debouncedEmail);
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(url, requestOptions);

      const result = await response.json();

      const { user } = result.data;

      if (user) {
        setUserExist(true);
      }

      console.log('result', result);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (debouncedEmail && !userExist) {
      userRegistrationStatusEmail(debouncedEmail);
    }
  }, [debouncedEmail, userExist]);

  const onPayment = async () => {
    setShowLoading(true);

    try {
      const formattedBirthdate = new Date(formData.birthdate)
        .toISOString()
        .split('T')[0];

      const payload = {
        ...formData,
        birthdate: formattedBirthdate,
      };

      const url = new URL(`${config.tncApiUrl}/event/register`);
      url.searchParams.append('eventId', config.eventId);
      url.searchParams.append(
        'eventRegistrationTypeId',
        selectedType?.id || ''
      );
      if (coupon) {
        url.searchParams.append('couponName', coupon);
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        setShowLoading(false);
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en el registro del evento');
      }

      const result = await response.json();

      if (result.data && result.data.transactionId) {
        window.location.href = `https://checkout.wompi.co/l/${result.data.transactionId}`;
      } else {
        setShowLoading(false);
        if (
          result.data &&
          result.data.message &&
          result.data.message === 'User registered Data'
        ) {
          loginStrava(result.data, formData.stravaId);
        }
      }
    } catch (error) {
      setShowLoading(false);
      console.error('Error en la solicitud de registro:', error);
      alert(error.message || 'Error al generar el pago');
    }
  };

  const applyCoupon = async (couponCode) => {
    setShowLoading(true);

    try {
      const response = await fetch(
        `${config.tncApiUrl}/coupon/validate?eventRegistrationTypeId=${selectedType.id}&couponName=${couponCode}`
      );
      const result = await response.json();

      if (result.message === 'OK') {
        const {
          originalPrice,
          discountedPrice,
          discountPercentage: percentage,
        } = result.data;
        setDiscountPercentage(percentage);
        setDiscount(originalPrice - discountedPrice);
        setFinalPrice(discountedPrice);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        alert('Cupón inválido');
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      setShowLoading(false);
    }
  };
  const fetchRunSegments = async () => {
    const segments = await fetchSegments('run');
    setRunSegments(segments);
  };

  const fetchBikeSegments = async () => {
    const segments = await fetchSegments('bike');
    setBikeSegments(segments);
  };

  const setSelectedTypFormData = () => {
    const userRegistrationStatus = JSON.parse(
      localStorage.getItem('userRegistrationStatus')
    );

    const { userChallenge } = userRegistrationStatus || {};

    if (userChallenge) {
      if (registrationTypes && registrationTypes.length > 0) {
        let selectedRegistrationType = null;

        if (userChallenge && userChallenge.sportType) {
          selectedRegistrationType = registrationTypes.find((type) => {
            if (userChallenge.sportType === 'bike') {
              return type.type === 'bike';
            }
            if (userChallenge.sportType === 'run') {
              return type.type === 'run';
            }
            return false;
          });
        }

        if (!selectedRegistrationType) {
          selectedRegistrationType = registrationTypes[0];
        }

        setSelectedType(selectedRegistrationType);
      } else {
        setSelectedType(registrationTypes[0]);
      }
    } else {
      setSelectedType(registrationTypes[0]);
    }
  };

  const populateFormData = () => {
    const stravaData = JSON.parse(localStorage.getItem('stravaAthlete'));
    const userRegistrationStatus = JSON.parse(
      localStorage.getItem('userRegistrationStatus')
    );

    const { refreshToken, stravaAthlete } = stravaData || {};
    const { user, userChallenge } = userRegistrationStatus || {};
    let updatedFormData = { ...formData };

    if (user) {
      setUserExist(true);
      updatedFormData = {
        ...updatedFormData,
        firstName: user.name || updatedFormData.firstName || '',
        lastName: user.lastName || updatedFormData.lastName || '',
        email: user.email || updatedFormData.email || '',
        phone: user.phone || updatedFormData.phone || '',
        gender: user.gender || updatedFormData.gender || '',
        birthdate: user.birthdate
          ? new Date(user.birthdate).toISOString().split('T')[0]
          : '',

        identificationCard:
          user.identificationCard || updatedFormData.identificationCard || '',
        address: user.address || updatedFormData.address || '',
        countryId: user.countryId || updatedFormData.countryId || '',
        stateId: user.stateId || updatedFormData.stateId || '',
        cityId: user.cityId || updatedFormData.cityId || '',
        cityName: user.cityName || updatedFormData.cityName || '',
        countryName: user.countryName || updatedFormData.countryName || '',
        refreshToken: user.refreshToken || updatedFormData.refreshToken || null,
        stravaId: user.stravaId || updatedFormData.stravaId || null,
      };
    }

    if (userChallenge) {
      updatedFormData = {
        ...updatedFormData,
        firstName: userChallenge.firstName || updatedFormData.firstName || '',
        lastName: userChallenge.lastName || updatedFormData.lastName || '',
        email: userChallenge.email || updatedFormData.email || '',
        phone: userChallenge.phone || updatedFormData.phone || '',
        gender: userChallenge.gender || updatedFormData.gender || '',
        birthdate: userChallenge.birthdate
          ? new Date(userChallenge.birthdate).toISOString().split('T')[0]
          : '',
        identificationCard:
          userChallenge.identificationCard ||
          updatedFormData.identificationCard ||
          '',
        sizeShirt: userChallenge.sizeShirt || updatedFormData.sizeShirt || '',
        segmentName:
          userChallenge.segmentName || updatedFormData.segmentName || '',
        segmentCity:
          userChallenge.segmentCity || updatedFormData.segmentCity || '',
        segmentId: userChallenge.segmentId || updatedFormData.segmentId || '',
        segmentStravaId:
          userChallenge.segmentStravaId ||
          updatedFormData.segmentStravaId ||
          '',
        bicycleBrand:
          userChallenge.bicycleBrand || updatedFormData.bicycleBrand || '',
        shoesBrand:
          userChallenge.shoesBrand || updatedFormData.shoesBrand || '',
        address: userChallenge.address || updatedFormData.address || '',
        countryId: userChallenge.countryId || updatedFormData.countryId || '',
        stateId: userChallenge.stateId || updatedFormData.stateId || '',
        cityId: userChallenge.cityId || updatedFormData.cityId || '',
        cityName: userChallenge.cityName || updatedFormData.cityName || '',
        countryName:
          userChallenge.countryName || updatedFormData.countryName || '',
        prTime: userChallenge.prTime || updatedFormData.prTime || null,
        prDate: userChallenge.prDate || updatedFormData.prDate || null,
        photoUrl: userChallenge.photoUrl || updatedFormData.photoUrl || null,
        sportType: userChallenge.sportType || updatedFormData.sportType || '',
        refreshToken:
          userChallenge.refreshToken || updatedFormData.refreshToken || null,
        stravaId: userChallenge.stravaId || updatedFormData.stravaId || null,
      };
    }

    if (updatedFormData.countryId) {
      fetchStates(updatedFormData.countryId);
    }
    if (updatedFormData.stateId) {
      fetchCities(updatedFormData.stateId);
    }

    if (stravaAthlete) {
      updatedFormData = {
        ...updatedFormData,
        firstName: stravaAthlete.firstname || updatedFormData.firstName || '',
        lastName: stravaAthlete.lastname || updatedFormData.lastName || '',
        stravaId: stravaAthlete.id || updatedFormData.stravaId || null,
        refreshToken: refreshToken || updatedFormData.refreshToken || null,
        photoUrl: stravaAthlete.profile || updatedFormData.photoUrl || null,
      };
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    fetchRunSegments();
    fetchBikeSegments();
    fetchRegistrationTypes();
    fetchCountries();
  }, []);

  useEffect(() => {
    if (formData.refreshToken) {
      getAccesToken(formData.refreshToken);
    }
  }, [formData.refreshToken]);

  useEffect(() => {
    if (formData.segmentId) {
      const selectedSegment = segments.find(
        (segment) => segment.id === formData.segmentId
      );

      if (selectedSegment?.stravaId && accessToken) {
        const newFormData = {
          ...formData,
          segmentStravaId: selectedSegment.stravaId,
          segmentName: selectedSegment.name,
          segmentCity: selectedSegment.cityName,
        };

        if (selectedType?.type === 'bike') {
          setFormData(newFormData);
          getStravaSegmentEfforts(selectedSegment.stravaId);
        } else {
          setFormData({ ...newFormData, prTime: null, prDate: null });
        }
      }
    }
  }, [formData.segmentId, accessToken, segments]);

  useEffect(() => {
    if (registrationTypes && registrationTypes.length > 0) {
      setSelectedTypFormData();
    }
  }, [registrationTypes]);

  useEffect(() => {
    if (selectedType) {
      setFinalPrice(selectedType.price);
      setDiscount(0);
      setCoupon('');
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedType && countries.length > 0) {
      populateFormData();
    }
  }, [selectedType, countries]);

  useEffect(() => {
    if (selectedType && bikeSegments.length > 0 && runSegments.length > 0) {
      setFormData({
        ...formData,
        segmentStravaId: '',
        segmentCity: '',
        segmentId: '',
        segmentName: '',
        sportType: selectedType?.type,
      });

      if (selectedType?.type === 'bike') {
        setSegments(bikeSegments);
      } else {
        setSegments(runSegments);
      }
    }
  }, [bikeSegments, runSegments, selectedType]);

  function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  const debouncedSetEmail = debounce((email) => setDebouncedEmail(email), 500);

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;

    setFormData({ ...formData, [field]: event.target.value });
    clearError(field);

    if (field === 'email' && !userExist) {
      console.log('value', value);
      debouncedSetEmail(value);
    }
  };

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    const selectedCountry = countries.find(
      (country) => country.id === countryId
    );

    setFormData({
      ...formData,
      countryId,
      countryName: selectedCountry ? selectedCountry.name : '',
      stateId: '',
      cityId: '',
    });
    setStates([]);
    setCities([]);

    clearError('countryId');
    fetchStates(countryId);
  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setFormData({ ...formData, stateId, cityId: '' });
    setCities([]);
    clearError('stateId');
    fetchCities(stateId);
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    const selectedCity = cities.find((city) => city.id === cityId);
    setFormData({
      ...formData,
      cityId,
      cityName: selectedCity ? selectedCity.city : '',
    });
    clearError('cityId');
  };

  const handleCouponApply = () => {
    applyCoupon(coupon);
  };

  const handlePayment = () => {
    if (validateFields()) {
      onPayment({ ...formData, registrationTypeId: selectedType.id });
    }
  };

  return (
    <div className='registration-page'>
      {showLoading && <Loading />}
      <Header signUp />
      <div className='container registration-page-container'>
        <Row>
          <Col xs={12}>
            <Typography
              variant='h4'
              align='center'
              style={{ marginTop: '20px', fontWeight: 'bold' }}
            >
              INSCRIPCIÓN
            </Typography>
            <Typography
              variant='h6'
              align='center'
              style={{ marginBottom: '20px', textTransform: 'uppercase' }}
            >
              Colombia Challenge 2025
            </Typography>
            {/* Mensaje sobre el uso de Strava */}
            <Typography
              variant='body1'
              style={{
                marginTop: '10px',
                marginBottom: '50px',
                textAlign: 'justify',
              }}
              className='hide-desktop'
            >
              Asegúrate de que el{' '}
              <strong style={{ color: '#ff4b03' }}>STRAVA</strong> que estás
              usando es el que vas a usar durante la carrera. Si registras la
              carrera con otra cuenta de{' '}
              <strong style={{ color: '#ff4b03' }}>STRAVA</strong>, no podremos
              registrar tu tiempo.
            </Typography>
            <Typography
              variant='body1'
              style={{
                marginTop: '10px',
                marginBottom: '50px',
                textAlign: 'center',
              }}
              className='hide-mobile'
            >
              Asegúrate de que el{' '}
              <strong style={{ color: '#ff4b03' }}>STRAVA</strong> que estás
              usando es el que vas a usar durante la carrera. <br />
              Si registras la carrera con otra cuenta de{' '}
              <strong style={{ color: '#ff4b03' }}>STRAVA</strong>, no podremos
              registrar tu tiempo.
            </Typography>
          </Col>

          {/* Formulario dinámico */}
          <Col xs={12} md={7}>
            <div className='entry-types'>
              <Typography variant='h5' className='form-title'>
                Selecciona tu Entrada
              </Typography>
              {registrationTypes.map((type) => (
                <div
                  key={type.id}
                  className={`entry-type ${
                    selectedType?.id === type.id ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedType(type)}
                >
                  <Typography variant='h6'>{type.ticket}</Typography>
                  <Typography
                    variant='body1'
                    style={{ fontWeight: 'bold' }}
                  >{`$ ${type.price.toLocaleString()}`}</Typography>
                </div>
              ))}
            </div>
            {selectedType && (
              <div className='registration-form'>
                <Typography variant='h5' className='form-title'>
                  Datos del Participante
                </Typography>
                <Row>
                  <Col xs={12} md={6} className='field-container'>
                    {/* Campo Nombre */}
                    <TextField
                      label='Nombre'
                      fullWidth
                      value={formData.firstName}
                      onChange={handleInputChange('firstName')}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </Col>
                  <Col xs={12} md={6} className='field-container'>
                    {/* Campo Apellido */}
                    <TextField
                      label='Apellido'
                      fullWidth
                      value={formData.lastName}
                      onChange={handleInputChange('lastName')}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </Col>
                  <Col xs={12} md={6} className='field-container'>
                    {/* Campo Número de Identificación */}
                    <TextField
                      label='Cédula'
                      fullWidth
                      value={formData.identificationCard}
                      onChange={handleInputChange('identificationCard')}
                      error={!!errors.identificationCard}
                      helperText={errors.identificationCard}
                    />
                  </Col>
                  <Col xs={12} md={6} className='field-container'>
                    {/* Campo Teléfono */}
                    <TextField
                      label='Teléfono'
                      type='tel'
                      fullWidth
                      value={formData.phone}
                      onChange={handleInputChange('phone')}
                      error={!!errors.phone}
                      helperText={errors.phone}
                    />
                  </Col>
                  <Col xs={12} md={6} className='field-container'>
                    {/* Campo Email */}
                    <TextField
                      label='Email'
                      type='email'
                      fullWidth
                      value={formData.email}
                      onChange={handleInputChange('email')}
                      error={!!errors.email}
                      helperText={errors.email}
                      disabled={userExist}
                    />
                  </Col>
                  <Col xs={12} md={6} className='field-container'>
                    {/* Campo Fecha de Nacimiento */}
                    <TextField
                      label='Fecha de Nacimiento'
                      type='date'
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={formData.birthdate}
                      onChange={handleInputChange('birthdate')}
                      error={!!errors.birthdate}
                      helperText={errors.birthdate}
                    />
                  </Col>
                  <Col xs={12} className='field-container'>
                    {/* Campo Género */}
                    <FormControl fullWidth>
                      <InputLabel>Género</InputLabel>
                      <Select
                        value={formData.gender}
                        onChange={handleInputChange('gender')}
                        style={{ textAlign: 'left' }}
                      >
                        {GENDERS.map((gender) => (
                          <MenuItem key={gender.value} value={gender.value}>
                            {gender.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.gender && (
                        <Typography color='error'>{errors.gender}</Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col xs={12} className='field-container'>
                    {/* Campo País */}
                    <FormControl fullWidth>
                      <InputLabel>País</InputLabel>
                      <Select
                        value={formData.countryId}
                        onChange={handleCountryChange}
                        style={{ textAlign: 'left' }}
                      >
                        {countries.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.countryId && (
                        <Typography color='error'>
                          {errors.countryId}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col xs={12} className='field-container'>
                    {/* Campo Estado */}
                    <FormControl fullWidth>
                      <InputLabel>Estado</InputLabel>
                      <Select
                        value={formData.stateId}
                        onChange={handleStateChange}
                        disabled={!states.length} // Deshabilitar si no hay estados
                        style={{ textAlign: 'left' }}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.state}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.stateId && (
                        <Typography color='error'>{errors.stateId}</Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col xs={12} className='field-container'>
                    {/* Campo Ciudad */}
                    <FormControl fullWidth>
                      <InputLabel>Ciudad</InputLabel>
                      <Select
                        value={formData.cityId}
                        onChange={handleCityChange}
                        disabled={!cities.length} // Deshabilitar si no hay ciudades
                        style={{ textAlign: 'left' }}
                      >
                        {cities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.city}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.cityId && (
                        <Typography color='error'>{errors.cityId}</Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col xs={12} className='field-container'>
                    {/* Campo Dirección */}
                    <TextField
                      label='Dirección'
                      fullWidth
                      value={formData.address}
                      onChange={handleInputChange('address')}
                      error={!!errors.address}
                      helperText={errors.address}
                    />
                  </Col>
                  <Col xs={12} className='field-container'>
                    <FormControl fullWidth>
                      <InputLabel>
                        {selectedType?.type === 'bike'
                          ? 'Segmento'
                          : 'Distancia'}
                      </InputLabel>
                      <Select
                        value={formData.segmentId}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            segmentId: e.target.value,
                            segmentStravaId:
                              segments.find((seg) => seg.id === e.target.value)
                                ?.stravaId || '',
                            segmentName: segments.find(
                              (seg) => seg.id === e.target.value
                            )?.name,
                            segmentCity: segments.find(
                              (seg) => seg.id === e.target.value
                            )?.cityName,
                          })
                        }
                        style={{ textAlign: 'left' }}
                      >
                        {segments.map((segment) => (
                          <MenuItem key={segment.id} value={segment.id}>
                            {selectedType?.type === 'bike' ? (
                              <>{`${segment.name} - ${segment.cityName}`}</>
                            ) : (
                              <>{`${segment.name}`}</>
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.segmentId && (
                        <Typography color='error'>
                          {errors.segmentId}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  <Col xs={12} md={6} className='field-container'>
                    {/* Campo Talla de Jersey */}
                    <FormControl fullWidth>
                      <InputLabel>Talla Jersey</InputLabel>
                      <Select
                        value={formData.sizeShirt}
                        onChange={handleInputChange('sizeShirt')}
                        style={{ textAlign: 'left' }}
                      >
                        {SIZES.map((size) => (
                          <MenuItem key={size.value} value={size.value}>
                            {size.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.sizeShirt && (
                        <Typography color='error'>
                          {errors.sizeShirt}
                        </Typography>
                      )}
                    </FormControl>
                  </Col>
                  {/* Campo Marca de Bicicleta */}
                  {selectedType?.type === 'bike' && (
                    <Col xs={12} md={6} className='field-container'>
                      <FormControl fullWidth>
                        <InputLabel>Marca de Bicicleta</InputLabel>
                        <Select
                          value={formData.bicycleBrand}
                          onChange={handleInputChange('bicycleBrand')}
                          style={{ textAlign: 'left' }}
                        >
                          {BIKES.map((bike) => (
                            <MenuItem key={bike} value={bike}>
                              {bike}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.bicycleBrand && (
                          <Typography color='error'>
                            {errors.bicycleBrand}
                          </Typography>
                        )}
                      </FormControl>
                    </Col>
                  )}
                  {/* Campo Marca de Zapatos */}
                  {selectedType?.type === 'run' && (
                    <Col xs={12} md={6} className='field-container'>
                      <FormControl fullWidth>
                        <InputLabel>Marca de Zapatos</InputLabel>
                        <Select
                          value={formData.shoesBrand}
                          onChange={handleInputChange('shoesBrand')}
                          style={{ textAlign: 'left' }}
                        >
                          {SHOES.map((shoe) => (
                            <MenuItem key={shoe} value={shoe}>
                              {shoe}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.shoesBrand && (
                          <Typography color='error'>
                            {errors.shoesBrand}
                          </Typography>
                        )}
                      </FormControl>
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </Col>

          {/* Resumen del pedido */}
          <Col xs={12} md={5}>
            {selectedType && (
              <div className='summary'>
                <Typography variant='h5' className='form-title'>
                  Resumen del Pedido
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='body1'>{`Entrada: `}</Typography>
                  <Typography variant='body1'>{`${selectedType.ticket}`}</Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='body1'>{`Precio: `}</Typography>
                  <Typography variant='body1'>{`$ ${selectedType.price.toLocaleString()}`}</Typography>
                </div>

                {!!discount && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='body1'>Descuento:</Typography>
                    <Typography variant='body1'>{`$ ${discount.toLocaleString()}`}</Typography>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant='h6'>Total:</Typography>
                  <Typography variant='h6'>{`$ ${finalPrice.toLocaleString()}`}</Typography>
                </div>
                {!discount && (
                  <Row>
                    <Col xs>
                      <TextField
                        label='Cupón'
                        fullWidth
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Button onClick={handleCouponApply}>Aplicar Cupón</Button>
                    </Col>
                  </Row>
                )}

                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handlePayment}
                >
                  {discountPercentage === 100 ? 'REGISTRARME' : 'PAGAR'}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationPage;
