import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import './login.scss';
import Loading from '../../components/loading';
import config from '../../config';
import {
  handleResponse,
  handleStravaResponse,
  serializeQuery,
} from '../../utils/handleResponse';
import stravaLogo from '../../assets/images/strava_white.png';
import idcard from '../../assets/images/idcard.svg';
import emailicon from '../../assets/images/emailicon.svg';
import idcarderror from '../../assets/images/idcarderror.svg';
import { stringify } from 'querystring';

const Login = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [authCode, setAuthCode] = useState(false);
  const [innerHeight, setInnerHeight] = useState(false);
  const [identificationCard, setIdentificationCard] = useState('');
  const [existsError, setExistsError] = useState(null);
  const [isGuest, setIsGuest] = useState(null);
  const [email, setEmail] = useState('');
  const [terms, setTerms] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const location = useLocation();
  // const [lastUpdate, setLastUpdate] = useState(null);

  const isWelcomePage = location.pathname === '/welcome';

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const code = query.get('code');
    setAuthCode(code);
    setInnerHeight(window.innerHeight);
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
      // setInnerHeight(window.innerHeight);
    });
    // getLastUpdate();
  }, []);

  // const getLastUpdate = async () => {
  //   setShowLoading(true);
  //   const headers = {
  //     'Content-Type': 'application/json',
  //   };
  //   const requestOptions = {
  //     method: 'GET',
  //     headers,
  //   };
  //   const response = await fetch(
  //     `${config.apiUrl}/auth/last-activity`,
  //     requestOptions
  //   );
  //   setShowLoading(false);
  //   try {
  //     const lastUpdates = await handleResponse(response);
  //     if (lastUpdates && lastUpdates.length > 0) {
  //       setLastUpdate(lastUpdates[0]);
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  const getAccesToken = async () => {
    setShowLoading(true);
    const url = new URL(`https://www.strava.com/oauth/token`);
    const params = {
      client_id: config.clientID,
      client_secret: config.clientSecret,
      code: authCode,
      grant_type: 'authorization_code',
    };
    const requestOptions = {
      method: 'POST',
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      await getStravaAthlete(body);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (authCode) {
      getAccesToken();
    }
  }, [authCode]);

  const openTerms = () => {
    window.open(
      'https://colombia-resources.s3.us-east-2.amazonaws.com/terms-colombia.pdf',
      '_blank'
    );
  };

  const loginStrava = async (userData, stravaId) => {
    setShowLoading(true);
    try {
      const payload = {
        stravaId,
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${config.tncApiUrl}/user/login`,
        requestOptions
      );

      if (!response.ok) {
        setShowLoading(false);
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en el registro del evento');
      }

      const result = await response.json();
      setShowLoading(false);
      localStorage.setItem(
        'userData',
        JSON.stringify({ ...userData, userToken: result.data.accessToken })
      );
      window.location.replace('/');
    } catch (error) {
      setShowLoading(false);
      console.error('Error en la solicitud de registro:', error);
      alert(error.message || 'Error al generar el pago');
    }
  };

  const userRegistrationStatus = async (stravaAthlete) => {
    setShowLoading(true);

    try {
      const url = new URL(`${config.tncApiUrl}/user-registration-status`);
      url.searchParams.append('sportEventId', config.eventId);
      url.searchParams.append('stravaId', stravaAthlete?.id || '');
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(url, requestOptions);

      const result = await response.json();

      if (
        !result.data.userEventRegistration ||
        (result.data.userEventRegistration &&
          !['PENDING', 'APPROVED'].includes(
            result.data.userEventRegistration.paymentStatus
          ))
      ) {
        localStorage.setItem(
          'userRegistrationStatus',
          JSON.stringify(result.data)
        );
        window.location.replace('/registration');
      } else if (
        result.data.userEventRegistration.paymentStatus === 'PENDING'
      ) {
        window.location.replace(
          `/payment-success?transactionId=${result.data.userEventRegistration.transactionId}`
        );
      } else {
        loginStrava(result.data, stravaAthlete?.id);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setShowLoading(false);
    }
  };

  // use current access token to call all activities
  const getStravaAthlete = async (access) => {
    setShowLoading(true);
    const url = new URL(`${config.stravaApiUrl}/athlete`);
    const headers = {
      Authorization: `Bearer ${access.access_token}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthlete = await handleResponse(response);
      localStorage.setItem(
        'stravaAthlete',
        JSON.stringify({ refreshToken: access.refresh_token, stravaAthlete })
      );
      localStorage.setItem('userRegistrationStatus', null);

      await userRegistrationStatus(stravaAthlete);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getAthlete = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/identification-card`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      identificationCard,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      return body;
    } catch (error) {
      console.log('error', error);
    }
  };

  const getGuest = async () => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/guest`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      email,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      return body;
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaSegmentEfforts = async (stravaAthlete, access) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts?segment_id=${athlete.athlete.segmentStravaId}`
    );
    const headers = {
      Authorization: `Bearer ${access.access_token}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthleteEfforts = await handleStravaResponse(response);

      // const stravaAthleteEffortFound = stravaAthleteEfforts.find(
      //   (stravaAthleteEffort) => `${stravaAthleteEffort.id}`.length === 11
      // );
      if (stravaAthleteEfforts && stravaAthleteEfforts.length > 0) {
        getStravaSegmentEffort(
          stravaAthleteEfforts[0].id,
          stravaAthlete,
          access
        );
      } else {
        await updateAthlete(stravaAthlete, access);
      }
    } catch (error) {
      await updateAthlete(stravaAthlete, access);
      console.log('error', error);
    }
  };

  const getStravaSegmentEffort = async (effortId, stravaAthlete, access) => {
    setShowLoading(true);
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts/${effortId.toString()}`
    );
    const headers = {
      Authorization: `Bearer ${access.access_token}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);

    setShowLoading(false);
    try {
      const stravaAthleteEffort = await handleResponse(response);
      await updateAthlete(
        stravaAthlete,
        access,
        stravaAthleteEffort.athlete_segment_stats.pr_date,
        stravaAthleteEffort.athlete_segment_stats.pr_elapsed_time
      );
    } catch (error) {
      await updateAthlete(stravaAthlete, access);
      console.log('error', error);
    }
  };

  const updateAthlete = async (stravaAthlete, access, prDate, prTime) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
    const url = new URL(`${config.apiUrl}/api/athletes/${athlete.athlete._id}`);
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const body = {
      photoUrl: stravaAthlete.profile,
      stravaId: stravaAthlete.id,
      refreshToken: access.refresh_token,
    };
    if (prDate && prTime) {
      body['prDate'] = prDate
        ? moment(prDate, 'YYYY-MM-DD').toISOString()
        : null;
      body['prTime'] = prTime || null;
    }
    const requestOptions = {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    try {
      const updatedAthlete = await handleResponse(response);
      setShowLoading(false);
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...updatedAthlete, isGuest: false },
          token: athlete.token,
        })
      );
      window.location.replace('/ranking');
    } catch (error) {
      setShowLoading(false);
      console.log('error', error);
    }
  };

  const getAthleteStravaId = async (stravaAthlete, access) => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/strava-id`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      stravaId: stravaAthlete.id,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      if (body && body.token) {
        const athlete = JSON.parse(localStorage.getItem('athleteLogged3'));
        const stravaUrl = new URL(
          `${config.apiUrl}/api/athletes/${athlete.athlete._id}`
        );
        const headers = {
          Authorization: `Bearer ${athlete.token}`,
          'Content-Type': 'application/json',
        };
        const bodyStrava = {
          photoUrl: stravaAthlete.profile,
          stravaId: stravaAthlete.id,
          refreshToken: access.refresh_token,
        };
        const stravaRequestOptions = {
          method: 'PUT',
          headers,
          body: JSON.stringify(bodyStrava),
        };
        const stravaResponse = await fetch(stravaUrl, stravaRequestOptions);
        setShowLoading(false);
        try {
          const updatedAthlete = await handleResponse(stravaResponse);
          localStorage.setItem(
            'athleteLogged3',
            JSON.stringify({
              athlete: { ...updatedAthlete, isGuest: false },
              token: athlete.token,
            })
          );
          window.location.replace('/');
        } catch (error) {
          console.log('error', error);
        }
      } else {
        await getStravaSegmentEfforts(stravaAthlete, access);
        // history.replace(
        //   `/sign-up?bearer=${access.access_token}&refreshToken=${access.refresh_token}`
        // );
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const isValidEmail = () => {
    if (!email) {
      setValidEmail(false);
      return;
    }
    let newValidEmail = false;
    if (/^[a-zA-Z0-9-_.]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test(email)) {
      newValidEmail = true;
    }
    setValidEmail(newValidEmail);
  };

  useEffect(() => {
    if (email) {
      isValidEmail();
    }
  }, [email]);

  const openIG = () => {
    const apiWs =
      window.innerWidth < 1024
        ? 'https://api.whatsapp.com/send?phone=573182000346'
        : 'https://web.whatsapp.com/send?phone=573182000346';
    window.open(apiWs, '_blank');
  };

  const sendGuest = async () => {
    const guest = await getGuest();

    if (guest && guest.token) {
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...guest.guest, isGuest: true },
          token: guest.token,
        })
      );
      window.location.replace('/');
    } else {
      history.replace(`/sign-up?email=${email}`);
    }
  };

  const login = () => {
    window.location.replace(
      `https://www.strava.com/oauth/authorize?client_id=${
        config.clientID
      }&response_type=code&redirect_uri=https://api.colombiachallenge.co/v1/strava/login-callback&approval_prompt=force&scope=read_all,profile:read_all,activity:read_all&state=${encodeURIComponent(
        'http://app.colombiachallenge.co/login'
      )}`
    );
  };

  // const login = () => {
  //   window.location.replace(
  //     `https://www.strava.com/oauth/authorize?client_id=${
  //       config.clientID
  //     }&response_type=code&redirect_uri=https://api.colombiachallenge.co/v1/strava/login-callback&approval_prompt=force&scope=read_all,profile:read_all,activity:read_all&state=${encodeURIComponent(
  //       'http://localhost:3000/login'
  //     )}`
  //   );
  // };

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  // const getStravaSegmentEfforts = async (athlete, segmentId) => {
  //   setShowLoading(true);
  //   const url = new URL(
  //     `${config.stravaApiUrl}/segment_efforts?segment_id=${segmentId}`
  //   );
  //   const headers = {
  //     Authorization: `Bearer ${access.access_token}`,
  //   };
  //   const requestOptions = {
  //     method: 'GET',
  //     headers,
  //   };
  //   const response = await fetch(url, requestOptions);
  //   setShowLoading(false);
  //   try {
  //     const stravaAthleteEfforts = await handleResponse(response);
  //     // const stravaAthleteEffortFound = stravaAthleteEfforts.find(
  //     //   (stravaAthleteEffort) => `${stravaAthleteEffort.id}`.length === 11
  //     // );
  //     if (stravaAthleteEfforts && stravaAthleteEfforts.length > 0) {
  //       getStravaSegmentEffort(athlete, access, stravaAthleteEfforts[0].id);
  //     } else {
  //       await goUpdate(athlete);
  //     }
  //   } catch (error) {
  //     console.log('error', error);
  //     await goUpdate(athlete);
  //   }
  // };

  // const getStravaSegmentEffort = async (athlete, effortId) => {
  //   setShowLoading(true);
  //   const url = new URL(`${config.stravaApiUrl}/segment_efforts/${effortId}`);
  //   const headers = {
  //     Authorization: `Bearer ${access.access_token}`,
  //   };
  //   const requestOptions = {
  //     method: 'GET',
  //     headers,
  //   };
  //   const response = await fetch(url, requestOptions);

  //   setShowLoading(false);
  //   try {
  //     const stravaAthleteEffort = await handleResponse(response);
  //     await goUpdate(
  //       athlete,
  //       stravaAthleteEffort.athlete_segment_stats.pr_date,
  //       stravaAthleteEffort.athlete_segment_stats.pr_elapsed_time
  //     );
  //   } catch (error) {
  //     await goUpdate(athlete);
  //     console.log('error', error);
  //   }
  // };

  const sendAthlete = async () => {
    const athlete = await getAthlete();

    if (athlete && athlete.token) {
      localStorage.setItem(
        'athleteLogged3',
        JSON.stringify({
          athlete: { ...athlete.athlete, isGuest: false },
          token: athlete.token,
        })
      );
      login();
    } else {
      setExistsError(true);
    }
  };

  return (
    <div className='login' style={{ minHeight: innerHeight }}>
      {showLoading && <Loading />}
      {/* <img className='login_logo' src={logo} alt='logo' /> */}
      {/* <button className='login_button' onClick={login}>
        Connect with
        <img src={stravaLogo} alt='strava' className='strava-logo' />
      </button> */}
      {!existsError && isGuest !== null && !isGuest && (
        <div className='login-info login-inputs'>
          <div className='login-container'>
            <img className='id_logo' src={idcard} alt='logo' />
            <div className='sign-up-description'>
              Por favor ingresa tu numero de cédula
            </div>
            <div className='field-container field-margin'>
              <FormControl fullWidth>
                <TextField
                  onChange={changeInput(setIdentificationCard)}
                  value={identificationCard}
                  type='number'
                  id='identificationCard'
                  label='Cédula'
                />
              </FormControl>
            </div>
            <div className='field-container'>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        setTerms(event.target.checked);
                      }}
                      checked={terms}
                    />
                  }
                  label={
                    <span className='terms-text'>
                      Acepto términos y condiciones. Ver{' '}
                      <span
                        className='link-term'
                        onClick={() => {
                          openTerms();
                        }}
                      >
                        documento
                      </span>
                    </span>
                  }
                  labelPlacement='end'
                />
              </FormControl>
            </div>
            {/* <button
              onClick={() => {
                sendAthlete();
              }}
              disabled={!identificationCard}
              className='submit-button'
            >
              Continuar
            </button> */}
            <button
              disabled={!terms}
              className='login_button'
              onClick={() => {
                sendAthlete();
              }}
            >
              Connect with
              <img src={stravaLogo} alt='strava' className='strava-logo' />
            </button>
          </div>
        </div>
      )}
      {!existsError && isGuest === null && (
        <div className='login-info'>
          <div className='login-container'>
            {isWelcomePage ? (
              <div className='sign-up-title'>
                BIENVENIDOS AL COLOMBIA CHALLENGE:
              </div>
            ) : (
              <div className='sign-up-title'>
                CONOCE A LOS PARTICIPANTES <br /> Y LOS RESULTADOS:
              </div>
            )}

            {/* <img
              className='strava-bnt'
              src={strava}
              alt='logo'
              onClick={() => {
                setIsGuest(false);
              }}
            /> */}

            <button
              className='login_button'
              onClick={() => {
                login();
              }}
            >
              {isWelcomePage ? 'INSCRIBIRME CON' : 'INICIAR SESIÓN CON'}
              <img src={stravaLogo} alt='strava' className='strava-logo' />
            </button>
            {/* <button
              onClick={() => {
                setIsGuest(false);
              }}
              className='submit-button'
            >
              Soy Participante
            </button> */}
            {/* <button
              onClick={() => {
                setIsGuest(true);
              }}
              className='submit-button guess-button'
            >
              Solo quiero chismosear
            </button> */}
          </div>
        </div>
      )}
      {existsError && (
        <div className='login-info login-inputs'>
          <div className='login-container'>
            <img className='id_logo' src={idcarderror} alt='logo' />
            <div className='sign-up-description'>
              No hemos encontrado tu cédula, la ultima actualización
              <br /> de registrados se realizo: <br />
              <br />
              Por favor intenta nuevamente. Si tienes algun
              <br />
              problema contáctanos a{' '}
              <a href='mailto:ceropresion@tncgames.co'>
                ceropresion@tncgames.co
              </a>
            </div>
            <div className='field-container'>
              <FormControl fullWidth>
                <TextField
                  onChange={changeInput(setIdentificationCard)}
                  value={identificationCard}
                  id='identificationCard'
                  type='number'
                  label='Cédula'
                />
              </FormControl>
            </div>
            <button
              onClick={() => {
                sendAthlete();
              }}
              disabled={!identificationCard}
              className='submit-button'
            >
              Volver a intentar
            </button>
            {/* <button
              onClick={() => {
                openIG();
              }}
              className='submit-button filter-button'
            >
              Contactar a TNC
            </button> */}
          </div>
        </div>
      )}
      {!existsError && isGuest !== null && isGuest && (
        <div className='login-info login-inputs'>
          <div className='login-container'>
            <img className='id_logo' src={emailicon} alt='logo' />
            <div className='sign-up-description'>
              Compartenos tu correo electronico
            </div>
            <div className='field-container'>
              <FormControl fullWidth>
                <TextField
                  onChange={changeInput(setEmail)}
                  value={email}
                  id='email'
                  label='Email'
                  error={!validEmail}
                  helperText={validEmail ? '' : 'Formato de email incorrecto'}
                />
              </FormControl>
            </div>
            <button
              onClick={() => {
                sendGuest();
              }}
              disabled={!(!!email && !!validEmail)}
              className='submit-button'
            >
              Continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
